import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

const OpenSign = () => {


  const { t } = useTranslation("main");
  const [isOpen, setIsOpen] = useState(true);
  const [closingSoon, setClosingSoon] = useState(false);

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const openingHours = [
    { day: "Monday", open: "15:00", close: "22:00" },
    { day: "Tuesday", open: "15:00", close: "22:00" },
    { day: "Wednesday", open: "15:00", close: "22:00" },
    { day: "Thursday", open: "15:00", close: "24:00" },
    { day: "Friday", open: "15:00", close: "25:00" },
    { day: "Saturday", open: "0:00", close: "2:00" },
    { day: "Saturday", open: "15:00", close: "25:00" },
    { day: "Sunday", open: "0:00", close: "2:00" },
  ];

  const equalTime = (now, offset) => {
    const day = now.getDay();
    const equaltime = (day * (24 * 60) + ((now.getHours() + (offset * -1)) * 60) + now.getMinutes());
    return (equaltime < 0 ? (equaltime + (7 * 24 * 60)) : (equaltime > (7 * 24 * 60) ? equaltime - (7 * 24 * 60) : equaltime));
  }

  const createTimeStamp = (dayName, time) => {
    const [hour, minute] = time.split(':');
    return ((days.indexOf(dayName) * 24 * 60) + (hour * 60) + (minute * 0));
  }

  const checkState = () => {

    const now = new Date();
    const location = equalTime(now, (now.getTimezoneOffset() / -60) + 5);
    const locationDayName = days[ Math.floor(location / (60 * 24)) ];
    const todayOpen = openingHours.filter(({day}) => day === locationDayName);

    if(todayOpen.length === 0) {
      
      setIsOpen(false);
      setClosingSoon(false);
      return;

    }
    else {
      let result = "closed";

      todayOpen.forEach(({day, open, close}) => {
        if(result === "closed") {

          const openTime = createTimeStamp(day, open);
          const closeTime = createTimeStamp(day, close);

          if (openTime <= location && (closeTime - 30) > location ) {
            result = "open";
          }
          
          else if (closeTime > location && (closeTime - 30) <= location ) {
            result = "soon";
          }

        }
      });

      setIsOpen((result !== "closed"));
      setClosingSoon(result === "soon");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkState();
    }, 60000); // Check every minute
    checkState();
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

 return (
  <div className="flex items-center justify-start cursor-default">
    {isOpen ? closingSoon ? <div className="bg-orange-500 text-black px-4 py-1 rounded-full uppercase font-medium">{ t("soon") }</div>
      : <div className="bg-green-500 text-black px-4 py-1 rounded-full uppercase font-medium">{ t("open") }</div>
      : <div className="bg-red-500 text-black px-4 py-1 rounded-full uppercase font-medium">{ t("closed") }</div>
    }
  </div>
);
};

export default OpenSign;
